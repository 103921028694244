Calendar = window.Calendar || {};
Calendar.admin = Calendar.admin || {};

Calendar.admin.init = function(selector, lang) {
	Calendar.admin.form = $(selector);
	Calendar.admin.lang = Calendar.admin.lang || {};
	lang = lang || {};
	Calendar.admin.lang = $.extend(lang, Calendar.admin.lang);
	$('#start_date').bind('change', function () {
		var $start = $('#start_date');
		var $finish = $('#finish_date');
		var startArr = $start.val().split('.');
		var start = startArr[2] + startArr[1] + startArr[0];
		var finishArr = $finish.val().split('.');
		var finish = finishArr[2] + finishArr[1] + finishArr[0];

		if ($finish.val() == '' || finish < start) {
			$('#finish_date').val($start.val());
		}
		$('#finish_date').datepicker("option", "minDate", new Date(startArr[2], startArr[1] - 1, startArr[0]));
	});

	$('#start_time, #finish_time').bind('keyup', function () {
		Calendar.admin.validateTime($(this));
	});

	$('#repeatType').bind('change',function () {
		$('.hideable').addClass('hide');
		$('.' + $(this).val()).removeClass('hide');
	}).trigger('change');

	$('#month_type-element .radio').bind('change', function() {
		var dayString = new String($('#start_date').val());
		var dayArr = dayString.split('.');
		var date = new Date(dayArr[2], dayArr[1] - 1, dayArr[0]);
		var val = $(this).val();

		if (val == 'day') {
			var html = Calendar.admin.lang['calendarRepeatMonthDayNotice'];
			html = html.replace('%day%', date.getDate());
		}
		else {
			var weekday = [
				Calendar.admin.lang['calendarSundayLCTH'],
				Calendar.admin.lang['calendarMondayLCTH'],
				Calendar.admin.lang['calendarTuesdayLCTH'],
				Calendar.admin.lang['calendarWednesdayLCTH'],
				Calendar.admin.lang['calendarThursdayLCTH'],
				Calendar.admin.lang['calendarFridayLCTH'],
				Calendar.admin.lang['calendarSaturdayLCTH']
			];

			var html = Calendar.admin.lang['calendarRepeatMonthWeekdayNotice'];

			html = html.replace('%week%', Math.ceil(date.getDate() / 7));
			html = html.replace('%weekday%', weekday[date.getDay()]);
		}

		if ($('#month_type-after').length < 1) {
			$('#month_type-element').after('<div id="month_type-after"></div>');
		}

		$('#month_type-after').html(html);
	});
	$('#month_type-element .radio:checked').trigger('change');

	Calendar.admin.form.find('input.submit').bind('click', function(e){
		var repeatDialog = $('#repeatDialog', Calendar.admin.form);
		if (repeatDialog.length > 0) {
			e.preventDefault();
			repeatDialog.dialog({
				width: 420,
				modal: true,
				buttons: [
					{
						text: Calendar.admin.lang['calendarRepeatSaveSingle'],
						click: function() {
							$('#saveType', Calendar.admin.form).val('single');
							Calendar.admin.form.submit();
						},
					},
					{
						text: Calendar.admin.lang['calendarRepeatSaveTrailing'],
						click: function() {
							$('#saveType', Calendar.admin.form).val('trailing');
							Calendar.admin.form.submit();
						},
					}
				]
			});
		}
	});
};

Calendar.admin.validateTime = function($this) {
	var error = true;
	var val = $this.val();

	$this.css('background', 'transparent');

	if (val.match(/^([0-1][0-9]:[0-5][0-9])|(2[0-3]:[0-5][0-9])$/) === null) {
		$this.css('background', '#fff0f0');
	}
};

Calendar.admin.upload = {
	init: function(urls, lang) {
		$('ul#images').sortable({
			'opacity': 0.8,
			'update': function (e, ui) {
				var $el = $(this);
				$el.sortable('disable').addClass('disabled');
				$.post(urls.sort, $(this).sortable('serialize'), function (d) {
					if (d.success !== true) {
						moya.message.add(lang.galleryErrorOnSave);
						$el.sortable('cancel');
					}
					$el.removeClass('disabled');
					$el.sortable('enable');
				});
			}
		});
		$('#ffile').uploadify({
			'uploader': '/_moya/js/jquery/uploadify/uploadify.swf',
			'script': urls.script,
			'cancelImg': '/_moya/js/jquery/uploadify/cancel.png',
			'auto': true,
			'multi': true,
			'folder': '/tmp',
			'fileDesc': lang.galleryImageUploadFileDescription,
			'fileExt': '*.jpg;*.jpeg;*.gif;*.png;*.JPG;*.JPEG;*.GIF;*.PNG',
			'buttonText': lang.pilotImageAdd,
			'queueID': 'ffileQueue',
			'onError': function () {
				$('#notice').html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
			},
			'onComplete': function (e, q, f, r) {
				var n = $('#notice');
				switch (r) {
					case 'noAlbum':
						n.html('<div class="message"><div class="error">' + lang.galleryErrorNoAlbumName + '</div></div>');
						break;
					case 'noSave':
						n.html('<div class="message"><div class="error">' + lang.galleryErrorOnSave + '</div></div>');
						break;
				}
			},
			'onAllComplete': function () {
				window.location.reload();
			}
		});
	}
};