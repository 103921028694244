site.hero = {
	init:function() {
		if (!$('body').hasClass('frontpage')) return false;
		if ($('#hero li').length > 1) {
			site.owl.init('#hero > ul', {
				loop: true,
				dots: true,
				nav: false,
				items: 1,
				navText: ["", ""],
				stopOnHover: true,
				autoplay: true,
				autoplayTimeout: 100000,
				autoplayHoverPause: true,
				animateOut: 'fadeOut',
				animateIn: 'fadeIn',
				onDrag: function () {
					$('#hero .owl-controls').hide();
				},
				onDragged: function () {
					$('#hero .owl-controls').show();
				},
				onInitialized: function() {
					$('#hero .owl-dot').keydown(function (e) {
						if (e.which == 13) {
							$(e.target).click();
						}
					});
				},
				onTranslated: function() {
					$('#hero .owl-item a').attr('tabindex', '-1');
					$('#hero .owl-item.active a').attr('tabindex', '4');
				}
			});
		}
	}
};

site.inits.push('site.hero.init()');
