site.dropdownMenu = {
	init: function () {
		$('.level1.children a', '#mainmenu').on('click', function (e) {
			var $body = $('body');
			if ($body.width()+15 >= 992) {
				if ($(this).parent().hasClass('level1') && !$(this).parent().hasClass('open')) {
					e.preventDefault();
				}
				$(this).parent().siblings().removeClass('open');
				$(this).parent().addClass('open');
				$body.toggleClass('dropdown-open');
				$('body.dropdown-open').on('click', function (e) {
					if (!$(e.target).parents('#mainmenu').length) {
						$body.removeClass('dropdown-open');
						$('.level1.open','#mainmenu').removeClass('open');
					}
				});
			}
		});
	}
};
site.inits.push('site.dropdownMenu.init()');
