site.tables = {
	init: function () {
		// Responsive tables
		$('table', '#main, #footer').each(function () {
			var $this = $(this);
			if ($this.hasClass('xs') || $this.hasClass('sm') || $this.hasClass('md')) {
				var cols = $(this).find('tr:first td').length;
				cols = (cols >= 2 && cols <= 4) ? 'c' + cols : '';
				$this.addClass(cols);
				$this.wrap('<div class="responsiveTable' + ((cols) ? ' cols' : '') + '"></div>');
			}
		})
	}
};

site.inits.push('site.tables.init()');