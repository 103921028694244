var site = site || {};
site.inits = [];

site.window = {
	scrollPosition: 0
};

site.device = {
	// Check if device is a touch device
	isTouch: function() {
		try {
			document.createEvent("TouchEvent");
			return true;
		} catch (e) {
			return false;
		}
	}
};


// A few layout methods
site.layout = {
	// Wrap elements into a div.row
	rowWrap: function(selectorBegin, selectorEnd, extraClasses) {
		if (selectorEnd == undefined) {
			selectorEnd = selectorBegin;
		}

		if (extraClasses == undefined) {
			extraClasses = '';
		}

		$(selectorBegin).nextUntil(selectorEnd).andSelf().add(selectorEnd)
			.wrapAll("<div class='row " +extraClasses+ "'></div>");
	}
};


site.owl = {
	// Shorthand to init owlcarousel
	init: function(id, options) {
		var $owl = $(id);
		if ($owl.length > 0) {
			$owl.addClass('owl-carousel'); // This is to fix a change made in owl 2.0.0-beta.3
			$owl.owlCarousel(options);
		}
	}
};



site.slider = {
	init:function() {
		if (!$('body').hasClass('frontpage')) return false;
		if ($('#slider li').length > 1) {
			site.owl.init('#slider ul', {
				loop: true,
				dots: false,
				nav: true,
				items: 1,
				navText: ["", ""],
				stopOnHover: true,
				autoplay: true,
				autoplayTimeout: 8000,
				autoplayHoverPause: true,
				animateOut: 'fadeOut',
				animateIn: 'fadeIn'
			});
		}
	}
};

site.quickLinks = {
	init: function () {
		if ($('ul','#quickLinks')) {
			$('#mainmenu .pw').append($('ul', '#quickLinks').clone().addClass('menu mmLevel1 quickLinks'));
		}
	}
};




// Run all the inits \o/
$(function() {
	$.each(site.inits, function(i, value) {
		var fun = new Function(value);
		fun();
	});

	site.quickLinks.init();
	site.slider.init();


	// Responsive vimeo player
	//var $i = $('iframe', '#center');
	//if ($i.length > 0 && $i.attr('src').indexOf('player.vimeo.com') != -1) {
	//	$i.wrap('<div class="vimeoPlayer"></div>');
	//}

	// Touch login
	$('.login').bind('click', function(e) {
		e.preventDefault();
		window.location = '/moya';
	});

	// Reorder language menu based on selected
	// Move is to the top
	//$li = $('.selected', '#divisions');
	//$ul = $('.menu', '#divisions');
	//$ul.prepend($li);
});
