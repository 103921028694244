Calendar = window.Calendar || {};

Calendar.init = function(selector, options) {
	var $el = $(selector);
	var calendarContent = $('.calendarContent', $el);
	$('.calendarCategories a', $el).click(function(e) {
		e.preventDefault();
		var $this = $(this);
		var $li = $this.parents('li');
		var $a = $li.find('a');
		if ($this.hasClass('all')) {
			$('.calendarCategories li:not(.all)').removeClass('active');
			$('.calendarCategories li:not(.all) a').css('background', '');
			$li.addClass('active');
		}
		else {
			var $all = $('.calendarCategories a.all').parents('li');
			if ($li.hasClass('active')) {
				$li.removeClass('active');
				$a.css('background-color', '');
				if ($('.calendarCategories .active').length === 0) {
					$all.addClass('active');
				}
			}
			else {
				$all.removeClass('active');
				$li.addClass('active');
				$a.css('background-color', $a.data('color'));
			}
		}
		Calendar.runFilter($el);
	});
	$('.calendarHeader .prevMonth', $el).click(function(e) {
		e.preventDefault();
		calendarContent.fullCalendar('prev');
	});
	$('.calendarHeader .nextMonth', $el).click(function(e) {
		e.preventDefault();
		calendarContent.fullCalendar('next');
	});
	var numberOfViews = 0;
	calendarContent.fullCalendar({
		year: options.year,
		month: options.month,
		monthNames: options.monthNames,
		monthNamesShort: options.monthNamesShort,
		dayNames: options.dayNames,
		dayNamesShort: options.dayNamesShort,
		buttonText: options.buttonText,
		views: (options.views) ? options.views : null,
		timeFormat: 'HH:mm { - HH:mm}', // uppercase H for 24-hour clock
		events: {
			url: options.source,
			cache: true
		},
		eventClick: function (calEvent, jsEvent, view) {
			if (options.eventOpensInDialog && calEvent.url) {
				var params = calEvent.url.split('/');
				if (params.length > 1) {
					var url = '/_/'+params[1]+'/calendar/event/view/'+params[params.length-1];
					$('.calendarEventDialog')
						.load(url)
						.dialog({
							width:'80%',
							title:calEvent.title,
							resize: 'auto',
							draggable: false
						});
				}

				return false;
			}
		},
		defaultView: options.defaultView,
		header: options.header,
		viewDisplay: function (view) {
			var mon = view.start.getMonth();
			var year = view.start.getFullYear();
			$('.calendarHeader .monthName', $el).html(view.title);
			//document.title = view.title + ' - ' + document.title.replace(/^[^ ]+ [0-9]+ - /, '');
			if (history && history.pushState) {
				var url = null;
				if (numberOfViews++ > 0) {
					url = window.location.href.replace(/\?.*/, '');
					var s = view.start.getTime() / 1000;
					var e = view.end.getTime() / 1000;
					url = url + "?start=" + s + "&end=" + e;
				}
				history.pushState(null, null, url)
			}
		},
		eventAfterAllRender: function(view) {
			Calendar.runFilter();
		},
		loading: function (isLoading, view) {
			var start = view.start.getTime() / 1000;
			var end = view.end.getTime() / 1000;
			$('.calendarEventList', $el).load(options.sourceBoxes + '?start=' + start + '&end=' + end);
			$(view.element).parents('.fc-content').toggleClass('loading', isLoading);
		},
		viewRender: function (view, $el) {
			var $content = $el.parents('.fc-content');
			if ($content.find('.loadingIndicator').length === 0) {
				$content.append('<div class="loadingIndicator"><img src="/static/local/calendar/img/spinner.png" /></div>')
			}
		}
	});
};

Calendar.runFilter = function(context) {
	if ($('.calendarCategories').length > 0) {
		if ($('.calendarCategories .active a.all', context).length !== 0) {
			$('.fc-event, .calendarEventList li', context).show();
		}
		else {
			$('.fc-event, .calendarEventList li', context).hide();
			$('.calendarCategories li.active', context).each(function() {
				var $this = $(this);
				$('.fc-event.' + $this.find('a').attr('class') + ', .calendarEventList .' + $this.find('a').attr('class'), context).show();
			});
		}
	}
};
