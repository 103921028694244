site.window = {
	scrollPosition: 0
};

site.nav = {
	// Init navigation scripts
	init: function() {
		// Add expand link to mobile menu
		$('.children', '#mainmenu ').prepend('<span class="expand" href="#"><i class="fa fa-angle-down"></span>');
		$('.selectedLevel1','#mainmenu').addClass('down');
		$('.selectedLevel2','#mainmenu').addClass('down');
		site.nav.bind();
	},
	bind: function() {
		// Mobile menu toggle
		$('#mainmenuToggle').click(function(e) {
			var menuOpen = $('body').hasClass('mainmenu-open');
			if (!menuOpen) {
				site.window.scrollPosition = $(window).scrollTop();
			}
			site.nav.mobileToggle(e, menuOpen, site.window.scrollPosition);
			e.preventDefault();
		});

		// Expand in mobile menu
		$('.expand', '#mainmenu').bind('click', function(e) {
			e.preventDefault();
			$(this).closest('li').toggleClass('down');
		});
	},
	// Toggle mobile menu
	mobileToggle: function(e, menuOpen, scrollPosition) {
		$('body').toggleClass('mainmenu-open');
		$(this).toggleClass('open');
		if (!menuOpen) {
			$(window).scrollTop(0);
			$('i', '#mainmenuToggle').removeClass('fa-bars').addClass('fa-close');
		}
		else {
			$(window).scrollTop(scrollPosition);
			$('i', '#mainmenuToggle').removeClass('fa-close').addClass('fa-bars');
		}
	},
	// Append language to site
	appendLanguage: function() {
		var $divisions = $('.menu', '#divisions');
		var $menu = $('ul.mmLevel1', '#mMenu');

		var $html = $('<li class="ele-language si level1"></li>');
		$divisions.find('li').each(function() {
			var $li = $(this).clone();
			$html.append($li.find('a'));
		});

		$menu.append($html);
	}
};

site.inits.push('site.nav.init()');